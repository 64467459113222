/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // $('#nav').hide();
        $('.hamburger').each(function(){

          $(this).click(function(){

            $(this).toggleClass('is-active');
            $('#nav').toggleClass('open');

          });


        });

        var addmore = $('.am-grouped').html();

        $('#select > li').click(function(){



          

          $(this).children('ul').toggleClass('open')

        })





$(function() {  
  // Here you register for the event and do whatever you need to do.
  $(document).on('data-attribute-changed', function() {

          

  });
  // end document on function
});






















      //  $(".initialbooking").initialize( function(){
      //     $(this).css("color", "blue");

      //     alert();
      // });


// jQuery('#timefilters').remove();

// var slottimeselect = jQuery('.am-extras-add');
// var slottimeselectinfo = jQuery('.am-add-element');


// jQuery('<div id="timefilters"></div>').insertBefore('.am-appointment-times.am-scroll');

// slottimeselect.insertBefore('#timefilters');
// slottimeselectinfo.removeClass('hidden').insertBefore('#timefilters');

// jQuery('.am-extras').on('change', function(){

// alert('change function')

// });






        jQuery('<a class="btn btn-success" href="#">1hour</a>').on('click', function(){

            jQuery(this).parent().find('a').removeClass('active');
            jQuery(this).toggleClass('active');
            alert('1hour timeslot function to be created');
            return false;
        }).appendTo('#timefilters');



        jQuery('<a class="btn btn-success" href="#">1hour 30min</a>').on('click', function(){
            jQuery(this).parent().find('a').removeClass('active');
            jQuery(this).toggleClass('active');
            alert('1hour 30min timeslot function to be created');
            return false;
        }).appendTo('#timefilters');

       // alert(addmore)


        





        // $( document ).on( 'change', '.am-select-service', function() {

       
         


        // });




        // $('.am-select-service-option').find('.el-input__inner').attr('placeholder' , 'How many DOgs?').hide()







        $(document).ready(function(){
          mapsize();
        })

        $(window).resize(function(){
          mapsize();
        })


        function mapsize() {

          $('.mapparent').each(function(){
            var ht = 0;
                ht = $(this).height();
            $(this).find('.acf-map').css({
              'height' : ht + 'px'
            });
          });

        }
        





        $('.el-scrollbar__view el-select-dropdown__list').on('change', function(){

          alert('hello');

        })


        var faq = $('#faqs').find('.faq');
        faq.each(function(){
          var toggler = $(this).find('h3');
          toggler.on('click', function(){
            $(this).toggleClass('toggled').next('p').slideToggle(240)
          })
          
        });

      

        

        


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


      $(document).ready(function(){
          mapsize();
        })

        $(window).resize(function(){
          mapsize();
        })


        function mapsize() {

          $('.mapparent').each(function(){
            var ht = 0;
                ht = $(this).height();
            $(this).find('.acf-map').css({
              'height' : ht + 'px'
            });
          });

        }


      // $(document).ready(function(){
      //   $('.balanceht').each(function(){
      //       rowhts($(this));
      //   })
      // });

      // $(window).resize(function(){
      //   rowhts($(this))
      // })



      // function rowhts(params) {

      //     var ht = params.outerHeight();
      //     var cht = params.find('.content').outerHeight();

      //     var ww = $(window).width();


      //     var pdd = ht-cht;

      //     if (ww > 768) {

      //       params.find('.content').css({
      //         'margin-top' : pdd/2 + 'px',
      //       })

      //     }

      // }




})(jQuery); // Fully reference jQuery after this point.
